import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import AssessmentIcon from "@material-ui/icons/Assessment";
import BackupIcon from "@material-ui/icons/Backup";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import StorageIcon from "@material-ui/icons/Storage";
import CodeIcon from "@material-ui/icons/Code";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import featuresStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionCRD({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest} style={{ background: "#1b2835" }}>
      <div className={classes.container}>
        <div className={classes.features}>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={AssessmentIcon}
                title="Analysis and Consulting"
                description={
                  <span>
                    <p style={{ color: "#fff" }}>
                      We provide targeted analysis services to help you evaluate
                      impact, best practices, and optional approaches. Our
                      ability to understand your unique business concerns
                      informs your installation of TRACE and ON-AIR Pro.
                    </p>
                  </span>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={PowerSettingsNewIcon}
                title="Installation and Deployment"
                description={
                  <span>
                    <p style={{ color: "#fff" }}>
                      You can be up and running quickly with our installation
                      and deployment specialists. Your implementation is
                      customized to your specific needs and prepard for
                      immediate production use.
                    </p>
                  </span>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={LiveHelpIcon}
                title="Technical Support"
                description={
                  <span style={{ color: "#fff" }}>
                    <p>
                      Our technical support team is available to answer
                      questions from end users, perform routine server
                      maintenance, troubleshoot ensuring your production
                      pipeline is alwasy supported.
                    </p>
                  </span>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={BackupIcon}
                title="Integration"
                description={
                  <span>
                    <p style={{ color: "#fff" }}>
                      Your systems don't live in a vacuum and need to be able to
                      talk together. We offer numerous existing integrations
                      with industry leading systems as well as offer custom
                      development services to build any integration you might
                      need.
                    </p>
                  </span>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={StorageIcon}
                title="Data Migration"
                description={
                  <span>
                    <p style={{ color: "#fff" }}>
                      We can import data in a wide variety of formats and
                      structures ensuring that your existing history can be
                      retained and you can hit the ground running with TRACE and
                      ON-AIR Pro.
                    </p>
                  </span>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <InfoArea
                icon={CodeIcon}
                title="Development"
                description={
                  <span>
                    <p style={{ color: "#fff" }}>
                      Your needs are ever changing requiring flexibity in
                      software solutions. TRACE and ON-AIR Pro can be extended
                      with custom development to meet your evolving needs now
                      and in the future.
                    </p>
                  </span>
                }
                iconColor="danger"
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
