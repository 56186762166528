import * as React from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle";
import "typeface-roboto";
import "typeface-roboto-slab";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import MyHeader from "components/Header/MyHeader";
import MyFooter from "components/Footer/MyFooter";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax";

// Sections for this page
import SectionFeatures from "./ServicesPage/Sections/SectionFeatures";

const useStyles = makeStyles(aboutUsStyle);

// markup
const ServicesPage = ({ ...rest }) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();

  return (
    <div>
      <MyHeader {...rest} />
      <Parallax
        image={require("assets/img/services.png")}
        filter="dark"
        small
      ></Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h3 className={classes.title} style={{ color: "black" }}>
                Client Responsive Development
              </h3>
              <h2 className={classes.title} style={{ color: "black" }}>
                Our design process begins with listening.
              </h2>
              <h5 className={classes.description}>
                At Thought Development, we believe that the best product design
                is based first on a clear understanding of the problems that
                need to be solved. And no one knows that better than our
                clients. This is client responsive development, and there is no
                other process that ensures that we put the right tools in the
                hands of our users.
              </h5>
              <h5 className={classes.description}>
                This is how TRACE was developed. The design team at Thought
                Development took their collective experience of the last twenty
                years in the element tracking system space and discussed all the
                major needs communicated to them. Then, we set out to make the
                best solution for each of these needs.
              </h5>
              <h5 className={classes.description}>
                Thought Development created the first promo ETS with On Air Pro.
                Now, TRACE picks up all the best aspects of OAP and brings it to
                the next level. All powered by twenty years of interacting with
                our clients and listening to their needs. TRACE has all the
                stuff you need, with nothing to bogs you down. We created the
                game, and now we’re changing the game.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
        <SectionFeatures />
      </div>
      <MyFooter {...rest} />
    </div>
  );
};

export default ServicesPage;
